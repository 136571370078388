@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:ital,wght@0,300;0,700;1,300&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

a {
  text-decoration: none;
}

body {
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  width: 1200px;
  margin: 0 auto;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cfd8dc;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.slick-list {
  height: 100%;
  outline: none !important;
}

.slick-slider {
  height: 100%;
  outline: none !important;
}

.slick-slider div {
  outline: none !important;
}

.slick-track {
  width: 100%;
  height: 100%;
  outline: none !important;
}

.hide-scrollbar {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none !important;
}

/* contrycode */
.form-control {
  width: 90px !important;
  border: none !important;
}

.flag-dropdown {
  border: none !important;
}
